@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;

  ::selection {
    background-color: rgba(0, 204, 255, 0.352);
  }
}

body {
  background-color: #010116;
}

.my-grid {

  @apply grid grid-cols-1 gap-0 mt-8 xl:mt-8;

  @apply md:grid-cols-2;

  @apply lg:grid-cols-3;

  @apply xl:grid-cols-4 gap-10 xl:mt-16;

}

::-webkit-scrollbar {
  width: 0.1rem;
}

::-webkit-scrollbar-track {
  background: rgb(1, 1, 22);
}

::-webkit-scrollbar-thumb {
  background: rgb(108, 178, 211);
  border-radius: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(90, 168, 151);
}

#leuiux-section {
  scroll-margin-top: 100px;
}

#stuiux-section {
  scroll-margin-top: 100px;
}

#seuiux-section {
  scroll-margin-top: 100px;
}

#iac-section {
  scroll-margin-top: 100px;
}

#cicd-section {
  scroll-margin-top: 100px;
}

#co-section {
  scroll-margin-top: 100px;
}

#msc-section {
  scroll-margin-top: 100px;
}

#csci-section {
  scroll-margin-top: 100px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.hover\:shadow-glow-white:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease-in-out;
}
