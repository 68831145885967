.card-container {
  perspective: 1000px; /* Enable 3D space for flip effect */
  position: relative;
  width: 100%;
  max-width: 350px; /* Reduced max width */
  height: 300px; /* Reduced height */
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-container:hover .card-inner {
  transform: rotateY(180deg);
  scale: 0.84;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
}

.card-front {
  background: rgba(0, 0, 0, 0.5); /* Darker background for the front */
}

.card-back {
  background: rgba(0, 0, 0, 0.5); /* Consistent background for the back */
  transform: rotateY(180deg);
}

.image-container {
  position: relative;
  width: 100%;
  height: 66%; /* Adjusted height for image container */
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-image {
  width: 100%;
  height: 100%;
  background-color: #4a4a4a; /* Placeholder color */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1rem; /* Adjusted font size */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 0, 255, 0.3), rgba(0, 255, 255, 0.3));
  opacity: 0.5;
  border-radius: 50%;
  z-index: -1;
}

.card-title {
  font-size: 1.25rem; /* Adjusted font size */
  color: white;
  margin-top: 0.5rem; /* Adjusted margin */
}

.card-batch {
  font-size: 0.875rem; /* Adjusted font size */
  color: #d1d1d1;
  margin-bottom: 0.5rem; /* Adjusted margin */
}

.achievements {
  overflow-y: auto;
  max-height: 100%; /* Adjusted height */
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5); /* Consistent background color */
  border-radius: 0.5rem;
}

.achievement {
  margin-bottom: 0.5rem;
}

.decorative-element {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  height: 4px;
  background-color: #4f46e5;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
